import React, {useEffect} from 'react';
import {Router} from '@reach/router';
import Route from '../../components/Route';
import FortunePage from '../../components/dashboard/FortunePage';
import {graphql} from 'gatsby';
import {getData, remove} from '../../services/storage';
import {useTranslation} from 'react-i18next';

const UnknownPage = () => {
  const {
    i18n: {resolvedLanguage},
  } = useTranslation();

  if (resolvedLanguage !== 'en') {
    window.location.href = `/${resolvedLanguage}?login=1`;
  } else {
    window.location.href = '/?login=1';
  }

  return null;
};

const Dashboard = () => {
  useEffect(() => {
    if (getData('quiz') !== null) {
      remove('quiz');
    }
  }, []);

  return (
    <>
      <Router basepath='/dashboard'>
        <Route path='/fortunes/:id' component={FortunePage} />
        <Route path='/fortunes/:id/:status' component={FortunePage} />
        <Route path='/fortunes' component={UnknownPage} />
      </Router>
      <Router basepath='/:lang/dashboard'>
        <Route path='/fortunes/:id' component={FortunePage} />
        <Route path='/fortunes/:id/:status' component={FortunePage} />
        <Route path='/fortunes' component={UnknownPage} />
      </Router>
    </>
  );
};

export default Dashboard;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["dashboard", "index"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
