import React, {useEffect, useState} from 'react';
import {checkoutOrder, Order} from '../../../services/api/orderAPI';
import {useTranslation} from 'react-i18next';
import SelectedCard from '../../fortuneQuiz/SelectedCard';
import Loader from '../../Loader';
import {StaticImage} from 'gatsby-plugin-image';
import {IoCheckmarkSharp, IoStarSharp} from 'react-icons/io5';
import {mapFromChars} from '../../../services/currencyMapper';
import {pushEvent} from '../../../services/gtmManager';
import Timer from './Timer';
import {get, store} from '../../../services/storage';
import {add, getUnixTime} from 'date-fns';
import {Helmet} from '../../Helmet';
import Reviews from '../../Reviews';
import * as Sentry from '@sentry/browser';

interface Props {
  fortune: Order;
  onPaymentStart: (fortune: Order) => void;
}

const Checkout = ({fortune, onPaymentStart}: Props) => {
  const {
    t,
    i18n: {resolvedLanguage},
  } = useTranslation();
  const [checkoutStarted, setCheckoutStarted] = useState<boolean>(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<boolean>(false);

  const storedSpecialOfferDeadlineTimestamp = get('special_offer_deadline_timestamp');
  const [specialOfferDeadline, setSpecialOfferDeadline] = useState<number | null>(
    storedSpecialOfferDeadlineTimestamp ? parseInt(storedSpecialOfferDeadlineTimestamp) : null
  );

  const onStartCheckoutClick = async () => {
    setCheckoutStarted(true);
    window.scrollTo(0, 0);

    pushEvent('offerCheckoutStart');

    if (fortune.payment_methods === null) {
      onPaymentMethodSelected('');
    }
  };

  const onPaymentMethodSelected = async (paymentMethod: string) => {
    setPaymentMethodSelected(true);

    try {
      const {data} = await checkoutOrder(fortune!.id, resolvedLanguage, paymentMethod);
      onPaymentStart(data.order);

      pushEvent('offerPaymentMethodSelected', {label: paymentMethod});

      window.location.href = data.redirect_url;
    } catch (e) {
      setPaymentMethodSelected(false);

      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (specialOfferDeadline === null) {
      const newDeadline = getUnixTime(add(new Date(), {hours: 9, minutes: 39, seconds: 24}));
      setSpecialOfferDeadline(newDeadline);
      store('special_offer_deadline_timestamp', newDeadline);
    } else if (specialOfferDeadline < getUnixTime(new Date())) {
      setSpecialOfferDeadline(null);
    }
  }, []);

  return (
    <>
      <Helmet title={t('fortune.checkout.header')}/>
      <section className='flex flex-col items-center lg:mb-8 mb-2 lg:pt-8 pt-4'>
        <StaticImage
          height={52}
          quality={100}
          placeholder='none'
          src='../../../images/logo.png'
          alt='Fortula logo with hands around glass globe'
        />
        <div className='max-w-xl my-6'>
          <h2 className='text-center text-3xl mb-2'>{t('fortune.checkout.header')}</h2>
          <h3 className='text-center text-xl mb-4'>{t('fortune.checkout.subheader')}</h3>
        </div>
        <div className='flex justify-center gap-4'>
          <SelectedCard cardSlug={fortune.order_data.cards[0]} pulse={false} />
          <SelectedCard cardSlug={fortune.order_data.cards[1]} pulse={false} />
          <SelectedCard cardSlug={fortune.order_data.cards[2]} pulse={false} />
        </div>
      </section>
      {fortune.status === 'created' && (
        <section className='lg:p-8 p-4 flex flex-col items-center'>
          {paymentMethodSelected && (
            <>
              <p className='mb-6'>{t('fortune.redirecting_to_checkout')}</p>
              <Loader />
            </>
          )}
          {checkoutStarted && !paymentMethodSelected && fortune.payment_methods !== null && (
            <div>
              <h1 className='text-4xl text-center'>{t('fortune.checkout.select_payment_method')}</h1>
              <div className='flex p-8 gap-6 lg:gap-4 flex-wrap justify-center lg:max-w-none max-w-sm'>
                {fortune.payment_methods.map((paymentMethod) => (
                  <div className='lg:w-auto w-full'>
                    <div className='text-xs lg:w-[150px] truncate text-center mb-1'>{paymentMethod.title}</div>
                    <button
                      key={paymentMethod.key}
                      onClick={() => onPaymentMethodSelected(paymentMethod.key)}
                      className='bg-white rounded-2xl lg:w-[150px] w-full h-[61px] flex justify-center items-center px-3'
                    >
                      {paymentMethod.logo_url ? (
                        <img src={paymentMethod.logo_url} alt={paymentMethod.title} />
                      ) : (
                        paymentMethod.title
                      )}{' '}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!checkoutStarted && !paymentMethodSelected && (
            <div className='flex flex-col items-center'>
              {/*todo: names only for LT, pytagorean, bday and biorythm disabled for now*/}
              <div className='max-w-lg mb-10 text-center'>
                <p className='mb-2 font-medium'>
                  {t('fortune.checkout.personal_reading')} {mapFromChars(fortune.price_currency)}
                  {fortune.price_amount}
                </p>
                <h4 className='text-4xl lg:mb-6 mb-8 font-medium'>{t('fortune.checkout.special_offer')}</h4>
                <div className='flex lg:flex-row flex-col items-center justify-center lg:gap-4 gap-3 flex-col-reverse'>
                  <button
                    onClick={onStartCheckoutClick}
                    className='btn btn-error border-0 bg-red-600 rounded-full text-white normal-case text-lg h-auto'
                  >
                    {t('fortune.pay')}
                  </button>
                  {specialOfferDeadline !== null && (
                    <div className='bg-white bg-opacity-20 py-2 px-4 rounded-full'>
                      <Timer deadlineTimestamp={specialOfferDeadline} />
                    </div>
                  )}
                </div>
              </div>
              <h4 className='text-center text-4xl mb-4'>{t('fortune.checkout.offer_includes')}</h4>
              <ul className='list-inside mb-4'>
                <li className='flex items-center gap-2 mb-2'>
                  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                  <p>{t('fortune.checkout.offer_tarot')}</p>
                </li>
                <li className='flex items-center gap-2 mb-2'>
                  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                  <p>{t('fortune.checkout.offer_zodiac')}</p>
                </li>
                <li className='flex items-center gap-2 mb-2'>
                  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                  <p>{t('fortune.checkout.offer_pythagorean')}</p>
                </li>
                <li className='flex items-center gap-2 mb-2'>
                  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                  <p>{t('fortune.checkout.offer_birthdate')}</p>
                </li>
                {resolvedLanguage === 'lt' && (
                  <li className='flex items-center gap-2 mb-2'>
                    <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />
                    <p>{t('fortune.checkout.offer_name')}</p>
                  </li>
                )}
                {/*<li className='flex items-center gap-2 mb-2'>*/}
                {/*  <IoCheckmarkSharp className='text-success flex-shrink-0' size={36} />*/}
                {/*  <p>{t('fortune.checkout.offer_biorhythm')}</p>*/}
                {/*</li>*/}
              </ul>
              <div className='mb-8'>
                <StaticImage width={200} src='../../../images/trust-badge.png' alt='Trust badge' />
              </div>
              <div className='flex gap-1 mb-8'>
                <IoStarSharp size={36} className='text-yellow-400' />
                <IoStarSharp size={36} className='text-yellow-400' />
                <IoStarSharp size={36} className='text-yellow-400' />
                <IoStarSharp size={36} className='text-yellow-400' />
                <IoStarSharp size={36} className='text-yellow-400' />
              </div>
              <div className='text-2xl mb-8'>{t('fortune.checkout.review_rating')}</div>
              <div className='text-center mb-8'>
                <p className='text-3xl mb-2'>{t('fortune.checkout.success_stories_title')}</p>
                <p className='text-xl'>{t('fortune.checkout.success_stories_subtitle')}</p>
              </div>
              <div className='mb-8'>
                <Reviews/>
              </div>
              <div className='flex lg:flex-row flex-col items-center justify-center lg:gap-4 gap-3 flex-col-reverse mb-4'>
                <button
                  onClick={onStartCheckoutClick}
                  className='btn btn-error border-0 bg-red-600 rounded-full text-white normal-case text-lg h-auto'
                >
                  {t('fortune.pay')}
                </button>
                {specialOfferDeadline !== null && (
                  <div className='bg-white bg-opacity-20 py-2 px-4 rounded-full'>
                    <Timer deadlineTimestamp={specialOfferDeadline} />
                  </div>
                )}
              </div>
              <div className='mb-8'>
                <StaticImage width={200} src='../../../images/trust-badge.png' alt='Trust badge' />
              </div>
            </div>
          )}
        </section>
      )}
      {fortune.status === 'processing' && (
        <section className='p-8 flex flex-col items-center'>
          <div className='mb-6'>{t('fortune.checkout.waiting_for_payment')}</div>
          <Loader />
        </section>
      )}
    </>
  );
};

export default Checkout;
