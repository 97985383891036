import React from 'react';
import {Order} from '../../../services/api/orderAPI';
import TarotDetails from './Details/TarotDetails';
import ZodiacDetails from './Details/ZodiacDetails';
import PythagoreanSquareDetails from './Details/PythagoreanSquareDetails';
import NameDetails from './Details/NameDetails';
import BirthdateDetails from './Details/BirthdateDetails';
import {Link} from 'gatsby-plugin-react-i18next';
import {useTranslation} from 'react-i18next';
import {Helmet} from '../../Helmet';

interface Props {
  order: Order;
}

const Details = ({order}: Props) => {
  const {t} = useTranslation();

  if (order.details.tarot === null) {
    return null;
  }

  return (
    <>
      <Helmet title={t('fortune.meta_title')}/>
      <h1 className='font-bold text-3xl text-center mb-8'>{t('fortune.header', {firstName: order.name})}</h1>
      <TarotDetails tarot={order.details.tarot} />
      {order.details.birthdate && (
        <>
          <hr className='my-10' />
          <BirthdateDetails birthdate={order.details.birthdate} />
        </>
      )}
      {order.details.pythagorean_square && (
        <>
          <hr className='my-10' />
          <PythagoreanSquareDetails pythagorean_square={order.details.pythagorean_square} />
        </>
      )}
      {order.details.name && (
        <>
          <hr className='my-10' />
          <NameDetails name={order.details.name} />
        </>
      )}
      {order.details.zodiac && (
        <>
          <hr className='my-10' />
          <ZodiacDetails zodiac={order.details.zodiac} />
        </>
      )}
      <div className='text-center mt-8'>
        <Link className='btn btn-warning btn-lg' to='/tarot-reading'>
          {t('fortune.order_new')}
        </Link>
      </div>
    </>
  );
};

export default Details;
