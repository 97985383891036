import React from 'react';
import {
  TbZodiacAquarius,
  TbZodiacAries,
  TbZodiacCancer,
  TbZodiacCapricorn,
  TbZodiacGemini,
  TbZodiacLeo,
  TbZodiacLibra,
  TbZodiacPisces,
  TbZodiacSagittarius,
  TbZodiacScorpio,
  TbZodiacTaurus,
  TbZodiacVirgo,
} from 'react-icons/tb';
import {ZodiacSign} from '../../../../services/fortune';

interface Props {
  slug: ZodiacSign;
}

const ZodiacIcon = ({slug}: Props) => {
  if (slug === 'aries') {
    return <TbZodiacAries size={42} />;
  }

  if (slug === 'taurus') {
    return <TbZodiacTaurus size={42} />;
  }

  if (slug === 'gemini') {
    return <TbZodiacGemini size={42} />;
  }

  if (slug === 'cancer') {
    return <TbZodiacCancer size={42} />;
  }

  if (slug === 'leo') {
    return <TbZodiacLeo size={42} />;
  }

  if (slug === 'virgo') {
    return <TbZodiacVirgo size={42} />;
  }

  if (slug === 'libra') {
    return <TbZodiacLibra size={42} />;
  }

  if (slug === 'scorpio') {
    return <TbZodiacScorpio size={42} />;
  }

  if (slug === 'sagittarius') {
    return <TbZodiacSagittarius size={42} />;
  }

  if (slug === 'capricorn') {
    return <TbZodiacCapricorn size={42} />;
  }

  if (slug === 'aquarius') {
    return <TbZodiacAquarius size={42} />;
  }

  if (slug === 'pisces') {
    return <TbZodiacPisces size={42} />;
  }

  return null;
};

export default ZodiacIcon;
