import React from 'react';
import {useTranslation} from 'react-i18next';
import {BirthdateResult} from '../../../../services/api/orderAPI';

interface Props {
  birthdate: BirthdateResult;
}

const BirthdateDetails = ({birthdate}: Props) => {
  const {t} = useTranslation();

  if (birthdate.type === null) {
    return null;
  }

  return (
    <section>
      <h2 className='text-center font-bold text-2xl mb-4'>{t('fortune.birthdate.header')}</h2>
      <div>
        <div className='text-center font-bold text-sm'>{birthdate.birthdate}</div>
        <p className='mb-4 font-bold text-center'>
          {t('fortune.birthdate.your_number', {
            number: t(`fortune.birthdate.${birthdate.number}`),
            type: birthdate.type,
          })}
        </p>
        <div className='mb-2'>
          <p className='font-bold'>{t('fortune.birthdate.character')}</p>
          <p>{birthdate.character}</p>
        </div>
        <div className='mb-2'>
          <p className='font-bold'>{t('fortune.birthdate.career')}</p>
          <p>{birthdate.career}</p>
        </div>
        <div className='mb-2'>
          <p className='font-bold'>{t('fortune.birthdate.love')}</p>
          <p>{birthdate.love}</p>
        </div>
        <div className='mb-2'>
          <p className='font-bold'>{t('fortune.birthdate.health')}</p>
          <p>{birthdate.health}</p>
        </div>
        <div className='mb-2'>
          <p className='font-bold'>{t('fortune.birthdate.suggestion')}</p>
          <p>{birthdate.suggestion}</p>
        </div>
        <div>
          <p className='font-bold'>{t('fortune.birthdate.talisman')}</p>
          <p>{birthdate.talisman}</p>
        </div>
      </div>
    </section>
  );
};

export default BirthdateDetails;
