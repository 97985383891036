import React, {useEffect, useState} from 'react';
import {getUnixTime} from 'date-fns';

interface Props {
  deadlineTimestamp: number;
}

const Timer = ({deadlineTimestamp}: Props) => {
  const [days, setDays] = useState<string>('00');
  const [hours, setHours] = useState<string>('00');
  const [minutes, setMinutes] = useState<string>('00');
  const [seconds, setSeconds] = useState<string>('00');

  useEffect(() => {
    const getTime = (deadline: number) => {
      const difference = deadline - getUnixTime(new Date());

      if (difference < 0) {
        clearInterval(interval);

        setDays('00');
        setHours('00');
        setMinutes('00');
        setSeconds('00');

        return;
      }

      setDays(
        Math.floor(difference / (60 * 60 * 24))
          .toString()
          .padStart(2, '0')
      );

      setHours(
        Math.floor((difference / (60 * 60)) % 24)
          .toString()
          .padStart(2, '0')
      );

      setMinutes(
        Math.floor((difference / 60) % 60)
          .toString()
          .padStart(2, '0')
      );

      setSeconds(
        Math.floor(difference % 60)
          .toString()
          .padStart(2, '0')
      );
    };

    getTime(deadlineTimestamp);

    const interval = setInterval(() => getTime(deadlineTimestamp), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <span className='w-[20px] inline-block'>{days}</span> : <span className='w-[20px] inline-block'>{hours}</span> :{' '}
      <span className='w-[20px] inline-block'>{minutes}</span> :{' '}
      <span className='w-[20px] inline-block'>{seconds}</span>
    </>
  );
};

export default Timer;
