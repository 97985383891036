import React from 'react';
import {useTranslation} from 'react-i18next';
import {ZodiacResult} from '../../../../services/api/orderAPI';
import ZodiacIcon from './ZodiacIcon';

interface Props {
  zodiac: ZodiacResult;
}

const ZodiacDetails = ({zodiac}: Props) => {
  const {t} = useTranslation();

  return (
    <section>
      <h2 className='text-center font-bold text-2xl mb-4'>{t('fortune.zodiac.header')}</h2>
      <div className='flex justify-center items-center mb-1'>
        <ZodiacIcon slug={zodiac.slug} />
        <h3 className='text-lg font-bold'>{zodiac.name}</h3>
      </div>
      <div className='text-center mb-4 font-bold text-sm'>{zodiac.dates}</div>
      <div className='mb-4'>
        <p className='whitespace-pre-wrap'>{zodiac.description}</p>
      </div>
      <div className='mb-4'>
        <h4 className='font-bold mb-2'>{t('fortune.zodiac.personality_description')}</h4>
        <p className='whitespace-pre-wrap'>{zodiac.personality_description}</p>
      </div>
      <div className='mb-4'>
        <h4 className='font-bold mb-2'>
          {t(`fortune.zodiac.decan.${zodiac.decan.index}`)} {zodiac.decan.dates}
        </h4>
        <p>{zodiac.decan.description}</p>
      </div>
      <div className='mb-4'>
        <h4 className='font-bold mb-2'>{t('fortune.zodiac.additional_description')}</h4>
        <p>{zodiac.additional_description}</p>
      </div>
      <div className='mb-4'>
        <h4 className='font-bold mb-2'>{t(`fortune.zodiac.gender.${zodiac.gender}`, {zodiacName: zodiac.name})}</h4>
        <p>{zodiac.gender_description}</p>
      </div>
      <div className='mb-4'>
        <h4 className='font-bold mb-2'>{t('fortune.zodiac.folk_wisdom', {zodiacName: zodiac.name})}</h4>
        <p>{zodiac.folk_wisdom}</p>
      </div>
    </section>
  );
};

export default ZodiacDetails;
