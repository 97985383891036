import React from 'react';
import {TarotResult} from '../../../../services/api/orderAPI';
import SelectedCard from '../../../fortuneQuiz/SelectedCard';
import {useTranslation} from 'react-i18next';

interface Props {
  tarot: TarotResult;
}

const TarotDetails = ({tarot}: Props) => {
  const {t} = useTranslation();

  return (
    <>
      <section className='flex flex-col mb-8'>
        <h2 className='text-center font-bold text-2xl mb-4'>
          {t('fortune.tarot.header')}
        </h2>
        <div className='flex justify-center gap-4'>
          <SelectedCard cardSlug={tarot.past.slug} pulse={false} />
          <SelectedCard cardSlug={tarot.present.slug} pulse={false} />
          <SelectedCard cardSlug={tarot.future.slug} pulse={false} />
        </div>
      </section>
      <section>
        <h2 className='font-bold mb-2'>
          {t('fortune.tarot.past')} - {tarot.past.name}
        </h2>
        <div className='flex lg:flex-row lg:items-start flex-col items-center gap-4'>
          <div>
            <SelectedCard cardSlug={tarot.past.slug} pulse={false} />
          </div>
          <p className='whitespace-pre-wrap'>{tarot.past.description}</p>
        </div>
      </section>
      <hr className='my-4' />
      <section className='mb-4'>
        <h2 className='font-bold lg mb-2'>
          {t('fortune.tarot.present')} - {tarot.present.name}
        </h2>
        <div className='flex lg:flex-row lg:items-start flex-col items-center gap-4'>
          <div>
            <SelectedCard cardSlug={tarot.present.slug} pulse={false} />
          </div>
          <p className='whitespace-pre-wrap'>{tarot.present.description}</p>
        </div>
      </section>
      <hr className='my-4' />
      <section>
        <h2 className='font-bold mb-2'>
          {t('fortune.tarot.future')} - {tarot.future.name}
        </h2>
        <div className='flex lg:flex-row lg:items-start flex-col items-center gap-4'>
          <div>
            <SelectedCard cardSlug={tarot.future.slug} pulse={false} />
          </div>
          <p className='whitespace-pre-wrap'>{tarot.future.description}</p>
        </div>
      </section>
    </>
  );
};

export default TarotDetails;
