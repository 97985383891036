import React, {PropsWithChildren, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StaticImage} from 'gatsby-plugin-image';
import {IoChevronDown} from 'react-icons/io5';
import {useAuthentication} from '../../context/authentication';
import AuthenticationModal from '../AuthenticationModal';
import LanguageSwitcher from '../LanguageSwitcher';

interface Props extends PropsWithChildren {
  hideHeaderAndFooter?: boolean;
}

const PageLayout = ({children, hideHeaderAndFooter = false}: Props) => {
  const {user, isAuthenticatingInBackground, logoutUser} = useAuthentication();
  const {t} = useTranslation();
  const [showAuthentication, setShowAuthentication] = useState(false);

  return (
    <>
      {showAuthentication && <AuthenticationModal onClose={() => setShowAuthentication(false)} />}
      <div className='bg-stars overflow-x-hidden min-h-screen'>
        {!hideHeaderAndFooter && (
          <nav className='navbar bg-base-300 mb-4 lg:mb-6'>
            <div className='flex-1'>
              <StaticImage
                height={36}
                quality={100}
                placeholder='none'
                src='../../images/logo.png'
                alt='Fortula logo with hands around glass globe'
              />
            </div>
            <div className='flex-none'>
              <ul className='menu menu-horizontal p-0'>
                {user !== null && (
                  <li>
                    <div>
                      <div className='dropdown dropdown-bottom dropdown-end'>
                        <label tabIndex={0} className='flex items-center font-bold cursor-pointer'>
                          {user.name}
                          <IoChevronDown />
                        </label>
                        <ul tabIndex={0} className='mt-1 dropdown-content menu shadow rounded text-black bg-white w-32'>
                          <li>
                            <button className='w-full' onClick={logoutUser}>
                              {t('authentication.logout')}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )}
                {user === null && !isAuthenticatingInBackground && (
                  <li>
                    <div className='p-0'>
                      <button className='btn' onClick={() => setShowAuthentication(true)}>
                        {t('authentication.login')}
                      </button>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        )}
        {children}
      </div>
      {!hideHeaderAndFooter && (
        <footer className='footer footer-center p-10 mt-10 bg-neutral text-white'>
          <div>
            <div>
              <LanguageSwitcher />
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default PageLayout;
