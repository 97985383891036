import React from 'react';
import {useTranslation} from 'react-i18next';
import {PythagoreanSquareResult} from '../../../../services/api/orderAPI';

interface Props {
  pythagorean_square: PythagoreanSquareResult;
}

const PythagoreanSquareDetails = ({pythagorean_square}: Props) => {
  const {t} = useTranslation();

  const renderNumberCount = (number: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9) =>
    pythagorean_square[number].count === 0 ? '-' : number.toString().repeat(pythagorean_square[number].count);

  return (
    <section>
      <h2 className='text-center font-bold text-2xl mb-4'>{t('fortune.pythagorean_square.header')}</h2>
      <table className='table mx-auto mb-4'>
        <tbody>
          <tr>
            <td className='bg-white border border-black rounded-none text-center'>
              <div>
                <div className='font-bold'>{renderNumberCount(1)}</div>
                <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[1].category}`)}</div>
              </div>
            </td>
            <td className='bg-white border border-black text-center'>
              <div className='font-bold'>{renderNumberCount(4)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[4].category}`)}</div>
            </td>
            <td className='bg-white border border-black rounded-none text-center'>
              <div className='font-bold'>{renderNumberCount(7)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[7].category}`)}</div>
            </td>
          </tr>
          <tr>
            <td className='bg-white border border-black rounded-none text-center'>
              <div className='font-bold'>{renderNumberCount(2)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[2].category}`)}</div>
            </td>
            <td className='bg-white border border-black text-center'>
              <div className='font-bold'>{renderNumberCount(5)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[5].category}`)}</div>
            </td>
            <td className='bg-white border border-black rounded-none text-center'>
              <div className='font-bold'>{renderNumberCount(8)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[8].category}`)}</div>
            </td>
          </tr>
          <tr>
            <td className='bg-white border border-black rounded-none text-center'>
              <div className='font-bold'>{renderNumberCount(3)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[3].category}`)}</div>
            </td>
            <td className='bg-white border border-black text-center'>
              <div className='font-bold'>{renderNumberCount(6)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[6].category}`)}</div>
            </td>
            <td className='bg-white border border-black rounded-none text-center'>
              <div className='font-bold'>{renderNumberCount(9)}</div>
              <div className='text-sm'>{t(`fortune.pythagorean_square.${pythagorean_square[9].category}`)}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        {Object.keys(pythagorean_square).map((number: any) => (
          <div key={number} className='mb-4'>
            <h3 className='font-bold text-2xl mb-1'>
              <>
                {pythagorean_square[number as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9].count !== 0
                  ? renderNumberCount(number)
                  : t(`fortune.pythagorean_square.0-${number}`)}{' '}
                (
                {t(
                  `fortune.pythagorean_square.${
                    pythagorean_square[number as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9].category
                  }`
                )}
                )
              </>
            </h3>
            <p className='whitespace-pre-wrap'>{pythagorean_square[number as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9].description}</p>
            {pythagorean_square[number as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9].similar_people && (
              <p className='mt-2 whitespace-pre-wrap'>{pythagorean_square[number as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9].similar_people}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default PythagoreanSquareDetails;
