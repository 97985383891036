import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from '@reach/router';
import {useTranslation} from 'react-i18next';
import {confirmOrderPayment, fetchOrder, Order} from '../../services/api/orderAPI';
import {clearHistoryState} from '../../services/state';
import Details from './FortunePage/Details';
import Checkout from './FortunePage/Checkout';
import {navigateWithLanguage} from '../../services/navigation';
import Loader from '../Loader';
import PageLayout from './PageLayout';
import {pushEvent} from '../../services/gtmManager';

const FortunePage = () => {
  const {
    i18n: {resolvedLanguage},
  } = useTranslation();

  const [fortune, setFortune] = useState<Order | null>(null);
  const location: any = useLocation();
  const {id, status} = useParams<{id: string; status?: 'payment-canceled' | 'payment-completed'}>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndSetFortune = async () => {
    const params = new URLSearchParams(location.search);

    const special = params.get('special') === '1';

    const {data} = await fetchOrder(id, resolvedLanguage, special);

    setFortune(data);

    if (isLoading) {
      setIsLoading(false);
    }
  };

  const confirmPaymentAndRedirectToFortune = async () => {
    const params = new URLSearchParams(location.search);
    const data = params.get('data');
    const ss1 = params.get('ss1');
    const ss2 = params.get('ss2');

    if (data && ss1 && ss2) {
      const {data: confirmedFortuneData} = await confirmOrderPayment(id, resolvedLanguage, {data, ss1, ss2});

      setFortune(confirmedFortuneData);

      pushEvent('offerCheckoutConfirm', {value: {finalPrice: confirmedFortuneData.price_amount}});
    }

    navigateWithLanguage(resolvedLanguage, `/dashboard/fortunes/${id}`, {replace: true});
  };

  useEffect(() => {
    let fetchInterval: any;

    if (fortune !== null && fortune.status === 'processing' && status !== 'payment-canceled') {
      fetchInterval = setInterval(fetchAndSetFortune, 7000);
    }

    return () => clearInterval(fetchInterval);
  }, [fortune?.status, status]);

  useEffect(() => {
    const state: Order | undefined = location.state;

    if (state?.id) {
      setFortune({
        id: state.id,
        name: state.name,
        status: state.status,
        details: state.details,
        order_data: state.order_data,
        price_amount: state.price_amount,
        price_currency: state.price_currency,
        language: resolvedLanguage,
        default_price_amount: state.default_price_amount,
        payment_methods: state.payment_methods,
      });

      setIsLoading(false);

      clearHistoryState();
    } else {
      if (status === 'payment-completed') {
        confirmPaymentAndRedirectToFortune();

        return;
      }

      if (status === 'payment-canceled') {
        navigateWithLanguage(resolvedLanguage, `/dashboard/fortunes/${id}`, {replace: true});

        return;
      }

      fetchAndSetFortune();
    }
  }, [id]);

  const renderStep = () => {
    if (fortune !== null) {
      if (fortune.status === 'ready') {
        return <Details order={fortune} />;
      }

      return <Checkout fortune={fortune} onPaymentStart={(fortune) => setFortune(fortune)} />;
    }
  };

  return (
    <>
      <PageLayout hideHeaderAndFooter={fortune?.status !== 'ready' || isLoading}>
        <main className={fortune?.status !== 'created' && !isLoading ? 'px-4' : ''}>
          <div
            className={`container max-w-4xl lg:p-8 p-4 rounded-2xl min-h-screen ${
              fortune?.status !== 'ready' || isLoading
                ? 'bg-base-100 text-primary'
                : 'bg-white bg-opacity-90 text-base-100'
            }`}
          >
            {isLoading && (
              <div className='flex justify-center items-center min-h-screen'>
                <Loader />
              </div>
            )}
            {renderStep()}
          </div>
        </main>
      </PageLayout>
    </>
  );
};

export default FortunePage;
