import React from 'react';
import {useTranslation} from 'react-i18next';
import {NameResult} from '../../../../services/api/orderAPI';

interface Props {
  name: NameResult;
}

const NameDetails = ({name}: Props) => {
  const {t} = useTranslation();

  return (
    <section>
      <h2 className='text-center font-bold text-2xl mb-4'>{t('fortune.name.header')}</h2>
      <div className='font-bold mb-1 text-lg'>{name.name}</div>
      {name.description !== null && <div className='mb-1'>{name.description}</div>}
      {name.name_day && (
        <div className='mb-1'>
          <strong>{t('fortune.name.name_day')}:</strong> {name.name_day}
        </div>
      )}
      <div className='mb-1'>
        <strong>{t('fortune.name.gender')}:</strong> {t(`fortune.name.gender_${name.gender}`)}
      </div>
      <div>
        <strong>{t('fortune.name.origin')}:</strong> {name.origin}
      </div>
    </section>
  );
};

export default NameDetails;
